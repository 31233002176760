export function videoBlock() {
    window.addEventListener('resize', function(event) {
        document.querySelectorAll('.video-carousel').forEach(function (node) {
          node.style.left = `0px`
        })
        document.querySelectorAll('.gallery-controls').forEach(function (node) {
          node.dataset.active = 1
        })
      }, true);
    
      document.querySelectorAll('.video-carousel').forEach(function (node) {
        node.style.left = `0px`
      })
    
      document.querySelectorAll('.gallery-controls').forEach(function (node) {
        let incrementer = node.querySelector('.increment-gallery')
        let decrementer = node.querySelector('.decrement-gallery')
        
        incrementer.addEventListener('click', (e) => {
    
          let index = node.dataset.index
    
          let gallerySlider = document.querySelector(`.video-carousel-${index}`)
          let galleryControls = document.querySelector(`.gallery-controls-${index}`)
    
          let active = galleryControls.dataset.active
          let max = galleryControls.dataset.max
    
          let videoItem = gallerySlider.querySelector('.video-item')
          let width = videoItem.getBoundingClientRect().width
  
    
          if (parseInt(active) < (parseInt(max) - 1) ) {
    
            active = parseInt(active) + 1;
            
            let leftValue = -( active * width )
            
            gallerySlider.style.left = `${leftValue}px`;
            galleryControls.dataset.active = active;
          }
        });
    
        decrementer.addEventListener('click', (e) => {
          let index = node.dataset.index
    
          let gallerySlider = document.querySelector(`.video-carousel-${index}`)
          let galleryControls = document.querySelector(`.gallery-controls-${index}`)
    
          let active = galleryControls.dataset.active
          let max = galleryControls.dataset.max
    
          let videoItem = gallerySlider.querySelector('.video-item')
          let width = videoItem.getBoundingClientRect().width
    
          if (parseInt(active) > 0) {
    
            active = parseInt(active) - 1;
            
            let leftValue = -(active * width)
    
            gallerySlider.style.left = `${leftValue}px`;
            galleryControls.dataset.active = active;
          }
        })
      });
    
      let videoUrl = checkOnVideoUrl();
      if (videoUrl) {
        // Get Caption
        let theVideoElm = document.querySelector('[data-video="' + videoUrl + '"]');
        theVideoElm.scrollIntoView();
        turnOnVideoPlayer(videoUrl);
      }
    
      // Check if there is a video arg in the url
      window.addEventListener('popstate', function (event) {
        let videoUrl = checkOnVideoUrl();
        if (videoUrl) {
          // Get Caption
          let theVideoElm = document.querySelector(
            '[data-video="' + videoUrl + '"]'
          );
          theVideoElm.scrollIntoView();
          turnOnVideoPlayer(videoUrl);
        }
      });
    
      // This is the button to close the video player
      const videoCloseButton = document.querySelector('.close-video-player');
      videoCloseButton.addEventListener('click', function (e) {
        closeVideoPlayer();
      });
    
      // This allows the video player to be closed by esc key
      document.body.addEventListener('keyup', function (e) {
        if (e.key == 'Escape') {
          closeVideoPlayer();
        }
      });
    
      document.querySelectorAll('.video-trigger').forEach(function (node) {
        node.addEventListener('click', function (e) {
          let elm = e.target;
          if (!elm.classList.contains('video-trigger')) {
            elm = elm.closest('.video-trigger');
          }
    
          const videoSrc = elm.dataset.video;
    
          turnOnVideoPlayer(videoSrc);
        });
      });

      function turnOnVideoPlayer(src) {
        const videoFrame = document.querySelector('.video-frame');
        videoFrame.setAttribute('src', src);
      
        const videoPlayer = document.querySelector('.bw-video-player');
        videoPlayer.classList.add('open');
      
        window.history.replaceState(null, null, '?v=' + src);
      }
      
      function closeVideoPlayer() {
        const videoPlayer = document.querySelector('.bw-video-player');
        videoPlayer.classList.remove('open');
      
        const videoFrame = document.querySelector('.video-frame');
        videoFrame.setAttribute('src', '');
      
        window.history.replaceState(null, '', window.location.pathname);
      }
      
      function checkOnVideoUrl() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('v');
      }
        
}