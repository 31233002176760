  <template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#0A1352"/>
      <path d="M20.9191 19.1322H26V20.8926H20.9191V26H19.0809V20.8926H14V19.1322H19.0809V14H20.9191V19.1322Z" fill="white"/>
    </svg>

  </template>
  
  <script>
  export default {
    props: {
      color: {
        type: String,
        default: '#1F1F15',
      },
    },
  };
  </script>
  