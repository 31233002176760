import VideoPlayPause from './video_play_pause';
import SearchPage from './search_page';
import LocationsSearch from './locations-search/locations_search.vue';


export default [
    ['video-play-pause', VideoPlayPause],
    ['locations-search', LocationsSearch],
    ['search-page', SearchPage]
];
