<template>
    <div
        v-if="video"
        id="videoPlayPause"
        class="video-play-pause text-white flex justify-between"
    >
        <a
            href="#"
            @click.prevent="play"
            :class="[
                'play text-xl flex items-center mr-2 mb-1',
                { 'pointer-events-none': !paused }
            ]"
        >
            &#9658;
        </a>
        <a
            href="#"
            @click.prevent="pause"
            :class="[
                'pause flex items-center',
                { 'pointer-events-none': paused }
            ]"
        >
            <span></span>
            <span></span>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        selector: {
            type: String,
            required: true
        }
    },

    mounted: function () {
        const video = document.querySelector(this.selector);
        
        if (video instanceof HTMLVideoElement) {
            this.video = video;
            this.paused = !video.autoplay;
            // just in case autoplay fails
            setTimeout(() => this.paused = video.paused, 1500);
        }
    },

    data: function () {
        return {
            video: null,
            paused: true
        };
    },

    methods: {
        play: function () {
            this.video.play();
            this.paused = false;
        },

        pause: function () {
            this.video.pause();
            this.paused = true;
        }
    }
}
</script>
