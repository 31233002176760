export function heroSlides() {

    var heroSlider = document.getElementById('slidesID');
    var activeHasBeenSet = false

    heroSlider.addEventListener('scroll', scrollHandler);

    // TODO: make more like a story, the pills grow into one another
    // Maybe remove the swiping all together

    function scrollHandler(e) {
        if (e.target.scrollLeft % e.target.offsetWidth === 0) {
            let scrollerIndex = (e.target.scrollLeft/e.target.offsetWidth) + 1

            var oldActivePill = document.getElementsByClassName("sliderPill active")[0];
            var activePill = document.getElementById('sliderPill-'+scrollerIndex);

            oldActivePill.classList.remove("active")
            activePill.classList.add("active")

        }
    }

    var interval = setInterval(function() {

        if (!activeHasBeenSet) {
            var firstPill = document.getElementById('sliderPill-1');
            console.log(firstPill)
            firstPill.classList.add("active")
            activeHasBeenSet = true;
        }

        else {
            var oldActivePill = document.getElementsByClassName("sliderPill active")[0];
            var currentActiveID = oldActivePill.id
            var currentIndex = currentActiveID.charAt(currentActiveID.length - 1);
    
            var newIndex = 1
            if (currentIndex < 3) {
                newIndex = parseInt(currentIndex) + 1;
            }
    
            // set the scroll
            var slideTo = heroSlider.offsetWidth * (newIndex-1)
            heroSlider.scrollLeft = parseInt(slideTo)
        }
    }, 5800)

      

}