  <template>
    <svg width="80" height="14" viewBox="0 0 80 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M77.8326 6.99997C77.8326 9.68249 75.658 11.8571 72.9754 11.8571C70.2929 11.8571 68.1183 9.68249 68.1183 6.99997C68.1183 4.31744 70.2929 2.14282 72.9754 2.14282C75.658 2.14282 77.8326 4.31744 77.8326 6.99997ZM79.8326 6.99997C79.8326 10.7871 76.7625 13.8571 72.9754 13.8571C69.5898 13.8571 66.7772 11.4034 66.219 8.1773H66.1183H7.16182L13.6636 11.2381L14.5684 11.664L13.7165 13.4735L12.8118 13.0476L2.2638 8.08207L0.407715 7.2083L2.23938 6.28445L12.7874 0.964251L13.6802 0.513911L14.5809 2.29963L13.688 2.74997L6.8929 6.1773H66.1183H66.1671C66.5736 2.77807 69.4668 0.142822 72.9754 0.142822C76.7625 0.142822 79.8326 3.21287 79.8326 6.99997Z" fill="#0A1352"/>
    </svg>
  </template>
  
  <script>
    export default {
      data: function () {
        return {};
      },
      props: {
        stroke: {
          type: String,
          default: '#2D2A22',
        },
      },
    };
  </script>
  