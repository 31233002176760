<template>
    <div class="w-full py-5">
      <div v-if="!isLoading && results.length" class="w-full">
        <div class="grid locations-grid gap-x-2.5 gap-y-[30px]">
          <listing-tile
            v-for="(event, index) in results"
            :key="index"
            :title="event.post_title"
            :image="event.image"
            :link="event.link"
            :address="event.address"
            :trimmedaddress="event.trimmed_address"
            :amenities="event.amenities"
            :variant="'tile'"
          />
        </div>
      </div>
      <div v-if="isLoading" class="w-full">
        <listings-skeleton />
      </div>
      <div v-if="!isLoading && !results.length">
        <p
          class="text-center text-xl font-bold text-umber/60 uppercase soehne-mono-buch mt-[25%]"
        >
          No results found
        </p>
      </div>
    </div>
  </template>
  
  <script>
  import ListingsSkeleton from '../misc/listings-skeleton.vue';
  import ListingTile from './listing_tile.vue';
  
  export default {
    props: {
      isLoading: {
        type: Boolean,
        default: '',
      },
      results: {
        type: Array,
        default: [],
      },
    },
    components: {
      ListingsSkeleton,
      ListingTile,
    },
  };
  </script>
  