<template>
    <div class="grid grid-cols-2 sm:grid-cols-3 gap-x-2.5 gap-y-[30px]">
      <div v-for="index in 9" class="flex flex-col gap-2.5" :key="index">
        <div
          class="aspect-square max-h-full w-full rounded-[10px] bg-[#F1EAD6] animate-pulse"
        />
        <div class="h-6 w-[136px] rounded-[10px] bg-[#F1EAD6] animate-pulse" />
        <div class="h-6 w-[100px] rounded-[10px] bg-[#F1EAD6] animate-pulse" />
        <div class="h-6 w-[90px] rounded-[10px] bg-[#F1EAD6] animate-pulse" />
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>