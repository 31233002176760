/* eslint-disable */
var mixitup = require('mixitup');
import mixitupMultifilter from '../../../js/custom/mixitup/multi';
import mixitupPagination from '../../../js/custom/mixitup/pagination';

function setMixer(mixer) {
    var hash;
    var url;

    if(navigator.userAgent.indexOf('AppleWebKit') != -1){
        hash = window.location.hash;
    } else {
        url = window.location.href;
        hash = url.substring(url.indexOf('#')+1);
    }

    if (hash) {
        const filters = hash.replace("#","").split("_");
        let page = 1;

        if (filters.length > 1 && filters[filters.length - 1].includes('page')) {
            page = filters.splice(-1,1)[0].split("-").splice(-1,1)[0];
        }

        mixer.multimix({
            filter: filters.join("."),
            paginate: page,
        })
        // mixer.setFilterGroupSelectors('dates', "."+filters[1]);
        // mixer.setFilterGroupSelectors('category', "."+filters[2]);
        // mixer.parseFilterGroups();

        console.log({group : mixer.getFilterGroupSelectors("dates") })

        // mixer.toggleOn(filters.join(".")).then(r => console.log({r}))

        $("[data-filter]").removeClass("mixitup-control-active");
        filters.forEach(el => {
            if(el){
                $("[data-filter='."+el+"']").addClass("mixitup-control-active");
            }
        });
        var currentValue = $(".c-filter-control__dropdown").find(".mixitup-control-active").parent()
        $(".c-dropdown__item").removeClass("c-dropdown__item--active")
        if(currentValue.length){
            currentValue.addClass("c-dropdown__item--active")
            $(".c-dropdown__current-value").text(currentValue.text())
        }

        // //make sure the dropdown has selected in its text
        // if (filters.indexOf("mix") === -1) {
        //     $('.c-filter-control-multi__label').text('SELECTED');
        //     $('.c-apartments-preview').addClass('c-apartments-preview--active');
        //
        // } else {
        //     $('.c-filter-control-multi__label').text('ALL');
        //     $('.c-apartments-preview').removeClass('c-apartments-preview--active');
        // }
    }else{
        mixer.setFilterGroupSelectors('dates', ".mix");
        mixer.setFilterGroupSelectors('category', ".mix");
        mixer.parseFilterGroups();
    }
}

function hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
}
function setArrowClick() {
    $(".c-filter__date-change").click(function () {
        const currentActive = $(".c-filter-control__group--months .mixitup-control-active")
        if(currentActive.length){
            const action = $(this).data('action')
            if("next" === action){
                currentActive.next().trigger("click")
            }else if("prev" === action){
                currentActive.prev().trigger("click")
            }
        }
    })
}
export function filter(){
    mixitup.use(mixitupPagination);
    mixitup.use(mixitupMultifilter);
    // const filterSelect = $(".c-filter-control__category-selector")
    const mixer = mixitup('.js-content-filter', {
        multifilter: {
            enable: true,
            logicWithinGroup: 'or',
            logicBetweenGroups: 'and',
            // parseOn: 'onchange',
        },
        pagination: {
            limit: 399 // impose a limit of 12 targets per page
        },
        animation: {
            "duration": 450,
            "nudge": true,
            "reverseOut": false,
            "effects": "fade translateZ(-100px)",
        },
        // load: {
        //     sort: 'name:asc'
        // },
        callbacks: {
            onMixEnd: function(state, abc) {
                const count = state.totalShow;
                const countTotal = state.totalMatching;
                const activeFilter = state.activeFilter.selector.replace(/\./g, "_").replace(/, /g, "_").replace(/ /g, "_");
                const activePage = state.activePagination.page ? state.activePagination.page : 1;
                const newHash = "#" + activeFilter + "_page-" + activePage;

                // $('.mixCount').html(count);
                $('.mixCountTotal').html(countTotal);
                const selector = state.activeFilter.selector.split(".").filter(el => el)[0]
                let activeFilterFlag = selector == 'mix' ? '.mix' : `.${selector}`;
                let activeFilterText = $( '.c-filter-control__group' ).find(`[data-filter='${activeFilterFlag}']`).data('dynamicTitle');
                if(activeFilterText){
                    $( '.c-filter__title.c-title--dynamic' ).text(activeFilterText);
                }
                setTimeout(() => {
                    var a = abc.getFilterGroupSelectors("dates")
                    var b = mixer.getFilterGroupSelectors("category")


                }, 100);
                const visibleEls = $(".js-content-filter .mix:visible")
                if(!visibleEls.length){
                    $(".c-filter__no-events-text").removeClass("c-filter__no-events-text--hidden")
                }else{
                    $(".c-filter__no-events-text").addClass("c-filter__no-events-text--hidden")
                }
                history.replaceState(undefined, undefined, newHash);
            },
            onMixClick: function(state, originalEvent) {
                // mixer.sort('name:asc')
                //reset pagination when using a filter
                if (hasClass(originalEvent.target, "c-filter-control-multi__btn")) {
                    state.activePagination.page = 1;
                }
                // $('html,body').animate({
                //     scrollTop: $(".js-content-filter").offset().top - 100
                // }, 1000);
            },
        }
    });
    // filterSelect.on('change', function(){
    //     // $container.mixItUp('filter', this.value);
    //     mixer.filter(this.value)
    // });
    setMixer(mixer);
    setArrowClick()
}
