<template>
    <div class="flex-1 pt-5 relative">
      <mapbox
        class="h-full w-full rounded-[10px]"
        @map-load="mapLoaded"
        access-token="pk.eyJ1IjoiYmVsbHdlYXRoZXItYWdlbmN5IiwiYSI6ImNseTRqeXFhdTAweGsya3E0aHhjeDZubnkifQ.KWDWHNfBs9qYzlgbbLnvLQ"
        :map-options="{
          container: mapContainerId,
          style: 'mapbox://styles/bellweather-agency/cly4k0rtr00im01qo5pu96vey',
          center: [-70.1846, 42.0547],
          zoom: 12,
          pitchWithRotate: false,
          dragRotate: false,
          touchZoomRotate: false,
          scrollZoom: false, //
        }"
        :nav-control="{ show: false }"
        :geolocate-control="{ show: false }"
      ></mapbox>
      <div
        class="absolute top-10 right-5 flex flex-col gap-2.5"
      >
        <button
          class="rounded-full bg-cloud aspect-square w-[34px] h-[34px] flex items-center justify-center"
          @click="zoomIn"
        >
          <zoom-in />
        </button>
        <button
          class="rounded-full bg-cloud aspect-square w-[34px] h-[34px] flex items-center justify-center"
          @click="zoomOut"
        >
          <zoom-out />
        </button>
      </div>
      <div class="lg:hidden">
        <Drawer
          :open="!!currentFeature || !!stagedID"
          :drawerClass="'!h-fit'"
          @dismiss="closePreview"
          :showClear="false"
        >
          <listing-tile
            v-if="!!currentFeature"
            :title="currentFeature.title"
            :trimmedaddress="currentFeature.trimmedaddress"
            :image="currentFeature.image"
            :link="currentFeature.link"
            :amenities="currentFeature.amenities"
            :variant="'card'"
          />
          <listing-tile-skeleton v-if="stagedID && !currentFeature" />
        </Drawer>
      </div>
      <div
        class="hidden lg:block absolute map-card right-5 bg-cloud rounded-[20px] sm:w-[390px]"
        v-if="currentFeature || stagedID"
      >
        <div class="tile-close">
          <button @click="closePreview">
            <CloseIcon />
          </button>
        </div>
        <listing-tile
          v-if="!!currentFeature"
          :title="currentFeature.title"
          :trimmedaddress="currentFeature.trimmedaddress"
          :image="currentFeature.image"
          :link="currentFeature.link"
          :amenities="currentFeature.amenities"
          :variant="'card'"
        />
        <listing-tile-skeleton v-if="stagedID && !currentFeature" />
      </div>
    </div>
  </template>
  
  <script>
  // This cannot be reactive, so must live outside of data
  let map = {};
  import Mapbox from 'mapbox-gl-vue';
  import CloseIcon from '../misc/svg-close.vue';
  import ListingTile from './listing_tile.vue';
  import Drawer from '../misc/filter-drawer.vue';
  // import MapPinPNG from '../../../static/images/map-pin.png';
  import ZoomIn from '../misc/svg-zoomin.vue';
  import ZoomOut from '../misc/svg-zoomout.vue';
  import ListingTileSkeleton from './listings_tile_skeleton.vue';
  
  export default {
    components: {
      mapbox: Mapbox,
      CloseIcon,
      ListingTile,
      Drawer,
      ZoomIn,
      ZoomOut,
      ListingTileSkeleton,
    },
    props: {
      loading: { type: Boolean, default: false },
      initialShowMarker: { type: Boolean, default: false },
      clustering: { type: Boolean, default: false },
      showInfoWindows: { type: Boolean, default: true },
      excludeCategories: { type: Array, default: () => [] },
      activeItem: { required: false },
      items: { type: Array, required: true },
      showNav: { type: Boolean, default: true },
      initialZoom: { default: null },
      initialFlyTo: { default: true },
      mapContainerId: { type: String, default: 'nepa-map' },
      isVisible: { type: Boolean, required: true },
    },
    data() {
      return {
        emptySourceData: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
          cluster: true,
          clusterMaxZoom: 15, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points
        },
        markers: [],
        initialLoad: true,
        currentFeature: null,
        stagedID: null,
      };
    },
    mounted() {
      this.emptySourceData.cluster = this.clustering;
      map[this.mapContainerId] = null;
      // Custom event to trigger resize from outside of vue
      window.addEventListener('mapbox:resize', () => {
        if (map[this.mapContainerId]) {
          if (typeof map[this.mapContainerId].resize === 'function') {
            map[this.mapContainerId].resize();
          }
          this.updateMarkers();
        }
      });
    },
    computed: {
      markerData() {
        return this.items
          .filter((item) => {
            //Remove items from excluded categories.
            if (
              this.excludeCategories.length &&
              item.all_categories !== undefined &&
              item.all_categories.length
            ) {
              let inExcludedCategory = item.all_categories.reduce(
                (carry, category) => {
                  if (this.excludeCategories.indexOf(category.term_id) > -1) {
                    carry = true;
                  }
                  return carry;
                },
                false
              );
              if (inExcludedCategory) {
                return false;
              }
            }
            return (
              item.hasOwnProperty('map') &&
              item.map !== null &&
              item.map.hasOwnProperty('lng') &&
              item.map.hasOwnProperty('lat')
            );
          })
          .map((item) => {
            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.map.lng, item.map.lat],
              },
              properties: {
                id: item.ID,
              },
            };
          });
      },
    },
    watch: {
      loading(value) {
        this.updateMarkers();
      },
      isVisible(value) {
        if (value && map[this.mapContainerId]) {
          this.$nextTick().then(() => map[this.mapContainerId].resize());
        }
      },
      async stagedID(value) {
        if (value) {
          const res = await fetch(
            `/wp-json/bw-search/single-listing?id=${value}`
          );
          const data = await res.json();
          console.log(data)
          if (data.ID) {
            this.currentFeature = {
              title: data.post_title,
              trimmedaddress: data.trimmed_address,
              link: data.link,
              image: data.image,
              amenities: data.amenities
            };
          }
        }
      },
    },
    methods: {
      closePreview() {
        this.currentFeature = null;
        this.stagedID = null;
      },
      zoomIn() {
        map[this.mapContainerId].zoomIn();
      },
      zoomOut() {
        map[this.mapContainerId].zoomOut();
      },
      async updateMarkers() {
        if (map[this.mapContainerId]) {
          this.clearMarkers();
          if (!this.markerData.length) {
            return;
          }
  
          let markerData = {
            type: 'FeatureCollection',
            features: this.markerData.map((val) => val),
          };
  
          // get data
          function mapSetData(mapThis, markerData) {
            var mapdata = map[mapThis.mapContainerId].getSource('items');
  
            if (mapdata) {
              mapdata.setData(markerData);
            }
  
            return markerData;
          }
  
          const md = await mapSetData(this, markerData);
          let bounds = new mapboxgl.LngLatBounds();
          md.features.map((marker) => {
            bounds.extend(marker.geometry.coordinates);
          });
          map[this.mapContainerId].fitBounds(bounds, {
            padding: 30,
            linear: false,
            maxZoom: 14,
          });
          // // fitBounds() has no minZoom, and we want Scranton to display always (?).
          if (this.initialLoad) {
            this.initialLoad = false;
            map[this.mapContainerId].once('moveend', () => {
              if (
                this.initialZoom &&
                map[this.mapContainerId].getZoom() < this.initialZoom
              ) {
                this.$nextTick().then(() => {
                  map[this.mapContainerId].flyTo({
                    center: map[this.mapContainerId].getCenter(),
                    zoom: this.initialZoom,
                  });
                });
              } else if (this.initialFlyTo) {
                this.$nextTick().then(() => {
                  map[this.mapContainerId].flyTo({
                    center: map[this.mapContainerId].getCenter(),
                    zoom: map[this.mapContainerId].getZoom(),
                  });
                });
              }
            });
          }
        }
      },
      clearMarkers() {
        if (this.popup) {
          this.popup.remove();
          this.popup = null;
        }
  
        var mapdata = map[this.mapContainerId].getSource('items');
        if (mapdata) {
          mapdata.setData(this.emptySourceData.data);
        }
      },
      getImageURI(callback) {
        // Create an Image element
        var img = new Image();
  
        // Create a canvas element
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');

        img.setAttribute('crossorigin', 'anonymous');
  
        // When the image is loaded, draw it to the canvas
        img.onload = function () {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
  
          // Create a new Image element for the output
          var outputImage = new Image();
          outputImage.onload = function () {
            // Call the callback function with the image as a parameter
            callback(outputImage);
          };
          // Convert canvas to an image
          outputImage.src = canvas.toDataURL('image/png');
        };
        // Convert the image to a data URL and set it as the source of the img element
        img.src = 'https://ptowntourism.com/wp-content/uploads/2024/06/Vector.png';

        return img;
      },
      mapLoaded(mapObject) {
        // TODO: Workaround for initial map size, we should eventually solve this with CSS.
        map[this.mapContainerId] = mapObject;
        this.$nextTick().then(() => {
          map[this.mapContainerId].resize();
  
          // Our items will be added below this layer - show under all city labels.
          let firstSymbolId = 'place-city-lg';
  
          // Cursor to pointer on feature hover.
          if (this.clustering) {
            map[this.mapContainerId].on('mousemove', (e) => {
              let features = map[this.mapContainerId].queryRenderedFeatures(
                e.point,
                {
                  layers: ['clusters', 'unclustered-point'],
                }
              );
  
              if (features.length > 0) {
                map[this.mapContainerId].getCanvas().style.cursor =
                  features[0].properties.Name !== null ? 'pointer' : '';
              } else {
                map[this.mapContainerId].getCanvas().style.cursor = '';
              }
            });
  
            // Cluster click - zoom in?
            map[this.mapContainerId].on('click', 'clusters', (e) => {
              this.currentFeature = null;
              this.stagedID = null;
              let zoomLevel = map[this.mapContainerId].getZoom() + 2;
              this.$nextTick().then(() => {
                map[this.mapContainerId].flyTo({
                  center: e.lngLat,
                  zoom: zoomLevel,
                });
              });
            });
          }
  
          map[this.mapContainerId].on('click', 'unclustered-point', (e) => {
            let coordinates = e.features[0].geometry.coordinates.slice();
            this.currentFeature = null;
            this.stagedID = e.features[0].properties.id;
  
            this.$nextTick().then(() => {
              map[this.mapContainerId].flyTo({
                center: coordinates,
                zoom: map[this.mapContainerId].getZoom(),
              });
            });
          });
  
          map[this.mapContainerId].addSource('items', this.emptySourceData);
  
          if (this.clustering) {
            map[this.mapContainerId].addLayer(
              {
                id: 'clusters',
                type: 'circle',
                source: 'items',
                filter: ['has', 'point_count'],
                paint: {
                  // Use step expressions (https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                  // with three steps to implement three types of circles:
                  //   * Blue, 20px circles when point count is less than 100
                  //   * Yellow, 30px circles when point count is between 100 and 750
                  //   * Pink, 40px circles when point count is greater than or equal to 750
                  'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#004FCB', // color
                    10, // count
                    '#004FCB', // color
                    100, // count
                    '#004FCB', // color
                  ],
                  'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    15, // radius
                    10, // count
                    25, // radius
                    25, // count
                    40, // radius
                  ],
                  'circle-opacity': 0.8,
                },
              }
            );
  
            map[this.mapContainerId].addLayer(
              {
                id: 'cluster-count',
                type: 'symbol',
                source: 'items',
                filter: ['has', 'point_count'],
                layout: {
                  'text-allow-overlap': true,
                  'text-ignore-placement': true,
                  'text-field': '{point_count_abbreviated}',
                  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                  'text-size': 15,
                },
                paint: {
                  'text-color': '#ffffff',
                },
              }
            );
          }
  
          this.getImageURI((MapPinImage) => {
            map[this.mapContainerId].addImage('custom-marker', MapPinImage);
            map[this.mapContainerId].addLayer(
              {
                id: 'unclustered-point',
                type: 'symbol',
                source: 'items',
                filter: ['!has', 'point_count'],
                layout: {
                  'icon-image': 'custom-marker',
                  'icon-allow-overlap': true,
                  'icon-size': 0.5,
                },
              }
            );
          });
  
          this.updateMarkers();
        });
      },
    },
  };
  </script>

  