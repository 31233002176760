<template>
    <div 
      class="filter-button ml-2 uppercase"
      role="checkbox"
      :aria-checked="value.toString()"
      tabindex="0"
      @click="toggle"
      @keydown.space.prevent="toggle"
    >
        <span>
          {{ text }}
        </span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
    },
    methods: {
      toggle() {
        this.$emit('input', !this.value);
      },
    },
  };
  </script>
  