<template>
    <div
      :class="[
        'listing-tile',
        {
          'gap-2 mb-2 tile': variant === 'tile',
          'card': variant === 'card',
        },
      ]"
    >
      <div class="flex">
        <div
          class="image-wrapper"
        >
          <img :src="image" class="h-full w-full object-cover" />
        </div>
        <div
          class='flex flex-col pl-2 w-full'
        >
          <div class="listing-card-title mb-2 md:mb-1 text-umber">
            {{ htmlDecode(title) }}
          </div>
          <a 
            :href="`https://www.google.com/maps/dir//${trimmedaddress} Provincetown, MA`"
            target="_blank"
            class="flex items-center mb-3 md:mb-2 address"
          >
            <location-pin />
            <span class="pl-2">{{ trimmedaddress }}</span>
          </a>
          <a v-if="link" class="location-link" :href="link" target="_blank">
            Visit Website
          </a>
          <div v-if="amenities" class="w-full flex-wrap pt-3 hidden md:flex">
            <div class="w-1/2 flex pt-2" v-for="amenity in amenities">
              <img class="location-amenity-image" :src="amenity.png" />
              <span class="location-amenity">
                {{ amenity.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="amenities" class="md:hidden flex flex-wrap">
        <div class="w-1/2 flex pt-3" v-for="amenity in amenities">
          <img class="location-amenity-image" :src="amenity.png" />
          <span class="location-amenity">
            {{ amenity.name }}
          </span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import LocationPin from '../misc/svg-location-pin.vue';
  
  export default {
    props: {
      image: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      link: {
        type: String,
        default: '',
      },
      address: {
        type: String,
        default: '',
      },
      trimmedaddress: {
        type: String,
        default: '',
      },
      variant: {
        type: String,
        default: '',
      },
      amenities: {
        type: Array,
        default: [],
      },
    },
    methods: {
      htmlDecode: function (input) {
        var doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.textContent;
      },
    },
    components: {
      LocationPin,
    },
  };
  </script>
  