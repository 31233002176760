<template>
    <div>
      <div
        @click="$emit('dismiss')"
        :class="[
          'absolute inset-0 opacity-0 transition-all invisible bg-umber/10 z-40',
          { 'opacity-100 !visible': open },
        ]"
      />
      <div
        :class="[
          'absolute flex flex-col translate-y-full overflow-hidden pt-[26px] px-[22px] h-[50vh] transition-transform bottom-0 left-0 w-full bg-cloud rounded-t-[20px] z-50',
          { '!translate-y-0': open },
          drawerClass,
        ]"
      >
        <div
          class="flex shrink-0 absolute right-2 top-2 justify-between items-center w-auto text-umber border-b border-umber/20"
        >
          <div class="flex-1 flex items-center">
            <button @click="$emit('dismiss')">
              <close-icon />
            </button>
          </div>
          <h2 v-if="title" class="flex-10 font-medium soehne-mono-buch text-[22px]">
            {{ title }}
          </h2>
          <button
            v-if="showClear"
            class="flex flex-1 shrink-0 justify-end"
            @click="$emit('clear')"
          >
            <span class="soehne-mono-buch text-right underline">Clear</span>
          </button>
          <span v-if="!showClear" class="flex-1" />
        </div>
        <div class="flex-1 py-[26px] overflow-auto scrollbar-hide">
          <slot></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CloseIcon from './svg-close.vue';
  
  export default {
    props: {
      title: {
        type: String,
        required: false,
      },
      open: {
        type: Boolean,
        default: false,
      },
      drawerClass: {
        type: String,
        default: '',
      },
      showClear: {
        type: Boolean,
        default: true,
      },
    },
    components: {
      CloseIcon,
    },
  };
  </script>
  