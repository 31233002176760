function dropdown() {

    // Closing the dropdown when user clicks outside of the event dropdown.
    $('body').click(function(){
        $(".js-dropdown").find('.c-dropdown__arrow').removeClass('c-dropdown__arrow--active');
        $(".c-dropdown__list").removeClass('c-dropdown__list--open');
    })

    // Opening dropdown when a user clicks on it.
    $('.js-dropdown').click(function (event) {
        event.stopPropagation();
        $(this).find('.c-dropdown__arrow').toggleClass('c-dropdown__arrow--active');
        $(this).next('.c-dropdown__list').toggleClass('c-dropdown__list--open');
    });

    // Clising dropdown when a user clicks on it.
    $('.c-dropdown__item').click(function(){
        var list  = $(this).parent(".c-dropdown__list")
        list.prev(".js-dropdown").find('.c-dropdown__arrow').toggleClass('c-dropdown__arrow--active');
        list.toggleClass('c-dropdown__list--open');
        list.prev(".js-dropdown").find(".c-dropdown__current-value").text($(this).text())
        $(this).siblings().removeClass("c-dropdown__item--active")
        $(this).addClass("c-dropdown__item--active")
    })
}

export default dropdown;
