<template>
  <div
    :class="[
      'pt-[30px] pb-[72px] md:min-h-screen',
      'lg:grid lg:grid-cols-[250px_1fr] xl:grid-cols-[300px_1fr] lg:gap-8 lg:px-[72px] lg:pt-10',
    ]"
  >
    <div
      :class="[
        'flex flex-col',
        {
          'h-[calc(100vh-70px)] lg:h-[calc(100vh-95px)]': currentView === 'map',
        },
      ]"
    >
      <div class="flex justify-between px-5 lg:px-0">
        <div class="flex w-full items-center">
          <div class="lodging-title text-center mx-auto mb-4 lg:mb-0">
            {{ title }}
          </div>
        </div>
      </div>
      <div class="flex location-map-controls">
        <div :class="['filter-button', sort === 'alphabetical' ? 'active' : '']" @click="sortAlphabetical">
          A - Z
        </div>
        <div :class="['filter-button ml-2', sort === 'reverse' ? 'active' : '']" @click="sortReverseAlphabetical">
          Z - A
        </div>
        <switch-with-text
          :value="currentView === 'map'"
          :text="currentView === 'map' ? 'View List' : 'View Map'"
          @input="toggleMap"
        />
      </div>
      <div class="flex flex-1">
        <listings-grid
          v-if="currentView !== 'map'"
          :results="results"
          :isLoading="isLoading"
        />
        <listings-map
          v-if="currentView === 'map'"
          :items="parsedMapItems"
          :isVisible="currentView === 'map'"
          :clustering="true"
          :loading="isLoading"
        />
      </div>
      <div
        v-if="currentView !== 'map' && !isLoading && totalPages > 0"
        class="m-auto flex items-center gap-[18px] location-map-pagination"
      >
        <button @click="goToPreviousPage" :disabled="currentPage === 1">
          <arrow-left-circle />
        </button>
        <span class="page-info mx-16">
          {{ `${currentPage} of ${totalPages}` }}
        </span>
        <button
          class="bw-rotate-180"
          @click="goToNextPage"
          :disabled="currentPage === totalPages"
        >
          <arrow-left-circle />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchWithText from '../misc/switch-with-text.vue';
import ListingsGrid from './listings_grid.vue';
import ListingsMap from './listings_map.vue';
import ArrowLeftCircle from '../misc/svg-arrow-left-circle.vue';

export default {
  components: {
    SwitchWithText,
    ListingsGrid,
    ListingsMap,
    ArrowLeftCircle,
  },
  props: ['pageTitle', 'categories'],
  created: function () {
  },
  methods: {
    goToPreviousPage: function () {
      this.$router.push({
        query: {
          ...this.$route.query,
          page_num: this.currentPage - 1,
        },
      });
      // window.scrollTo({ top: 800, behavior: 'smooth' });
      const mapContainer = document.getElementById("mapContainer");
      mapContainer.scrollIntoView({ behavior: 'smooth' });
    },
    goToNextPage: function () {
      this.$router.push({
        query: {
          ...this.$route.query,
          page_num: this.currentPage + 1,
        },
      });
      // window.scrollTo({ top: 800, behavior: 'smooth' });
      const mapContainer = document.getElementById("mapContainer");
      mapContainer.scrollIntoView({ behavior: 'smooth' });
    },
    toggleMap: function (val) {
      this.$router.push({
        query: {
          ...this.$route.query,
          currentView: this.currentView === 'map' ? undefined : 'map',
        },
      });
    },
    sortAlphabetical: function () {
      this.sort = 'alphabetical'
      this.fetchData()
    },
    sortReverseAlphabetical: function () {
      if (this.sort == 'alphabetical') {
        this.sort = 'reverse'
      }
      else {
        this.sort = 'alphabetical'
      }
      this.fetchData()
    },
    fetchData: async function () {
      this.isLoading = true;
      const query = this.$route.query;
      const page_num = query.page_num;

      const searchParams = new URLSearchParams();

      if (this.currentView === 'map') {
        searchParams.append('page_num', -1);
      } else {
        searchParams.append('page_num', page_num || 1);
      }

      let categoryString = "";

      if (this.parsedCats.length) {
        for (let i = 0; i < this.parsedCats.length; i++) {
          categoryString = categoryString + this.parsedCats[i].category.ID
          if ( 0 < i < (this.parsedCats.length - 1) ) {
            categoryString = categoryString + ','
          }
        }
      }

      searchParams.append('cats', categoryString);
      
      searchParams.append('sort', this.sort);

      const size = Array.from(searchParams).length;

      console.log(`${window.location.origin}/wp-json/bw-search/locations/${size > 0 ? `?${searchParams.toString()}` : ''}`)

      const res = await fetch(
        `${window.location.origin}/wp-json/bw-search/locations/${
          size > 0 ? `?${searchParams.toString()}` : ''
        }`
      ).then((res) => res.json());

      const { total_pages, total_results } = res.page_data;
      this.totalPages = total_pages;
      this.totalResults = total_results;

      const locations = res.locations;

      if (locations) {
        this.results = locations?.filter((l) => !!l.post_title) || [];
      }
    
      this.isLoading = false;
      
    },
  },
  mounted: function () {
    this.parsedCats = JSON.parse(this.categories);
    this.fetchData();
  },
  watch: {
    $route: function () {
      this.fetchData();s
    },
  },
  computed: {
    title: function () {
      return this.pageTitle;
    },
    free: function () {
      return this.$route.query.free === 'true';
    },
    currentView: function () {
      return this.$route.query.currentView;
    },
    currentPage: function () {
      return parseInt(this.$route.query.page_num) || 1;
    },
    parsedMapItems: function () {
      return this.results.map((res) => ({
        ...res,
        map: {
          lat: res.lat,
          lng: res.lng,
        },
      }));
    }
  },
  data: function () {
    return {
      parsedCats: [],
      results: [],
      sort: 'alphabetical',
      isLoading: false,
      totalResults: 0,
      totalPages: 1,
    };
  },
};
</script>
