/*
 * Sliders
 *
 * Calling the Slick Slider
 */

import 'slick-carousel';

var slider_dots;
const defaultSettings = {
    arrows: true,
    infinite: true,
    pauseOnHover: true,
    autoplay: false
};

export function gallerySlider() {

    $('.js-slider-gallery').each(function () {
        const $child_slider = $(this);

        var $status = $('.js-paging-info');

        slider_dots = Boolean($child_slider.attr('data-dots'));
        const $next_slider = $child_slider.next();
        const child_settings = Object.assign(defaultSettings, {
            autoplaySpeed: 3600,
            speed: 600,
            cssEase: 'ease-out',
            useTransform: true,
            focusOnSelect:true,
            slidesToScroll: 1,
            centerMode: true,
            lazyLoad: 'ondemand',
            variableWidth: true,
            infinite: true,
            rows: 0,
            prevArrow: $child_slider.parent().find('.js-slider-gallery-prev'),
            nextArrow: $child_slider.parent().find('.js-slider-gallery-next'),
            responsive: [{
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    prevArrow: $child_slider.parent().find('.js-slider-gallery-prev'),
                    nextArrow: $child_slider.parent().find('.js-slider-gallery-next'),
                }
            }]
        });

        $child_slider.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            $status.html(i + '<span class="middle">of</span>' + slick.slideCount);
        });

        $child_slider.not('.slick-initialized').slick(child_settings);
    });
}
