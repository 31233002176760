export function mediaSlider(){
    console.log("Media Slider")
    // max
    // current index
    const scroller = document.querySelector('.media-mentions-scroller');
    const decrement = document.querySelector('.media-mentions-decrement-page');
    const increment = document.querySelector('.media-mentions-increment-page');

    if (increment) {
        increment.addEventListener('click', () => {
            let max = scroller.dataset.max
            let currentIndex = scroller.dataset.index
            if (currentIndex < max) {
                scroller.dataset.index = parseInt(currentIndex) + 1
                scroller.style.left = "-"+(parseInt(scroller.dataset.index) - 1) * 100 + "%";
            }
        })
    }
    if (decrement) {
        decrement.addEventListener('click', () => {
            let max = scroller.dataset.max
            let currentIndex = scroller.dataset.index
            if (currentIndex > 1) {
                scroller.dataset.index = parseInt(currentIndex) - 1
                scroller.style.left = "-"+(parseInt(scroller.dataset.index) - 1) * 100 + "%";
            }
        })
    }
}
