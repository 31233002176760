/*
 * MixItUp Multi Filtering Plugin
 *
 */
let mixitup = require( 'mixitup' );
let mixItUpMultiFilterExtension = require( '../../custom/mixitup/mixitup-multifilter' );

function setFieldsFilter() {
    mixitup.use( mixItUpMultiFilterExtension );
    var activeId = $( '#default-active' ).attr( 'data-filter' );
    var activeType = $( '#default-active' ).attr( 'data-display' );

    var mixer = mixitup( '.c-location-map__locations', {
        selectors: {
            target: '.mix',
        },
        animation: {
            enable: false
        },
        multifilter: {
            enable: true, // enable the multifilter extension for the mixer
            parseOn: 'onclick',
        },
        load: {
            filter: activeId,
            sort: 'title:asc'
        },
        callbacks: {
            onMixEnd: setFirst
        }
    }, void 0, true );

    setFirst();

    $( '.c-location-map__map-toggle-btn' ).click( function() {


        $( '#map-section' ).toggleClass( 'c-location-map__location-wrapper--hidden' );
        $( '#card-section' ).toggleClass( 'c-location-map__location-wrapper--hidden' );

        if ( $( this ).text().trim() == 'Hide Map' ) {
            $( this ).text( 'Show Map' );
        } else {

            var selectedFilter = $(".mixitup-control-active.js-map-location-btn").eq(0);

            var id = selectedFilter.data( 'id' );
            var filter = selectedFilter.data( 'filter' ).substring(1);

            setTimeout(function(){
                $(".js-map-location-scroll").scrollTop(0)
                $('.js-map-location-card.'+filter).eq(0).find(".c-card-location__right h2").trigger("click")
            }, 500)

            $( this ).text( 'Hide Map' );
        }
    } );

    function setFirst() {
        $( '.js-map-location-scroll .c-location-map__location' ).removeClass( 'c-location-map__location--first' );
        $( '.js-map-location-scroll .c-location-map__location:visible' ).first().addClass( 'c-location-map__location--first' );
    }
}

export default setFieldsFilter;
