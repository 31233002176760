var wayPointWhoWeAreTriggered = false;
import 'waypoints/lib/noframework.waypoints.min.js';
import Vivus from "vivus"


function cardSVG() {
    const waypoint = new Waypoint({
        element: document.getElementById('cardSVG'),
        handler: () => {
            new Vivus('seasonal-svg', { duration: 200 });
        }
    });
}

export default cardSVG;