  <template>
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13.5" cy="13.5" r="13.5" transform="rotate(90 13.5 13.5)" fill="#0A1352"/>
      <path d="M14.5661 13.5307L17.5975 16.562L16.5472 17.6123L13.5159 14.5809L10.4687 17.6281L9.37189 16.5314L12.4191 13.4842L9.38775 10.4528L10.438 9.40257L13.4693 12.4339L16.5313 9.37192L17.6281 10.4687L14.5661 13.5307Z" fill="white"/>
    </svg>
  </template>
  
  <script>
  export default {
    data: function () {
      return {};
    },
    props: {
      stroke: {
        type: String,
        default: '#1F1F15',
      },
      isDesktop: {
        type: Boolean,
        default: true,
      },
    },
  };
  </script>
  