import $ from 'jquery';
var GoogleMapsLoader = require( 'google-maps' );

export function multiMarkerMap ( elem ) {
    GoogleMapsLoader.KEY = $( elem ).data( 'key' );
    GoogleMapsLoader.load( function( google ) {
        let map;
        let markers = [];
        let selectedMarker = null;
        let firstID = "none";

        let scrolling = false;
        let url = "/wp-json/wp/v2/location/location-category/";

        const $map = $( elem );
        const mobileWidth = 992;
        const mapStyles = [];
        const mapArgs = {
            zoom: $map.data( 'zoom' ),
            center: new google.maps.LatLng( 42.052952, -70.186447 ),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            streetViewControl: false,
            mapId: 'a916f7d1298832da'
        };

        function createMap( el, args ) {
            map = new google.maps.Map( el, args );
        }

        function createMarkers( markers, map, globalMarkers ) {
            globalMarkers.splice(0,globalMarkers.length);

            markers && markers.forEach( function ( el, i ) {
                if ( i == (markers.length-1) ) {
                    firstID = el.location_id
                }
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng( el.lat, el.lng ),
                    map: map,
                    icon: $map.data( 'active' ),
                    icons: {
                        base: el.location_custom_pin,
                        active: $map.data( 'active' )
                    },
                    zIndex: 100,
                    infoWindow: new google.maps.InfoWindow(),
                    id: el.location_id
                });

                google.maps.event.addListener( marker, 'click', (function (marker) {
                    return function() {
                        const { id } = marker;

                        setSelectedMarker( id, true, true );
                        scrollToCard(id);

                        selectedMarker = id;
                    }
                } )( marker ) );

                globalMarkers.push( marker );
            });

            if ( globalMarkers && globalMarkers.length > 0 ) {
                setSelectedMarker( globalMarkers[0].id );
                scrollToCard( globalMarkers[0].id );
                selectedMarker = globalMarkers[0].id;
            }
        }

        function removeMarkers( markers ) {
            markers && markers.forEach(el => {
                if( el && typeof el.setMap === "function") {
                    el.setMap( null );
                }
            });
        }

        function getLocations (
                url = url,
                {map, markers}
            ) {
            removeMarkers( markers );
            $.ajax({
                url: url,
                success: function ( result ) {
                    if ( result.status === "success" ) {
                        const markerList = result.results && result.results.map( el => {
                            const {
                                google_map_location,
                                ...rest
                            } = el;

                            return {
                                ...rest,
                                ...google_map_location
                            }
                        } );

                        if ( markerList && markerList.length > 0 ) {
                            const lat = parseFloat(markerList[0].lat);
                            const lng = parseFloat(markerList[0].lng);
                            map.panTo({lat, lng});
                        }

                        createMarkers( markerList, map, markers );
                    }
                }
            });
        }

        function deselectAllMarkers() {
            markers.map( marker => {
                if(marker.icons.base) {
                    marker.setIcon( marker.icons.base );
                }
                marker.setZIndex( 100 );
                marker.infoWindow.close();
            });
        }

        function setSelectedMarker( id, setOpacity = false, zoomFlag = false ) {
            deselectAllMarkers();

            if ( map.getZoom() == 12 && zoomFlag ) {
                map.setZoom($map.data( 'zoom' ));
            }

            markers && markers.forEach( marker => {
                if ( setOpacity ) {
                    marker.setOpacity(0);
                }

                if ( marker.id === id ) {
                    marker.setOpacity(1);
                    marker.setZIndex(1000);
                    marker.setIcon(marker.icons.active);
                    map.panTo(marker.getPosition());
                }
            } );

            var allCards        = $( ".c-location-map__location--mobile" );
            var oldCard         = $( ".c-location-map__location--mobile.is-active" );
            var currentCard     = $( ".c-location-map__location--mobile[data-id='"+id+"']" );
            var currentCardAll  = $( ".c-location-map__location[data-id='"+id+"']" );
            var oldIndex        = allCards.index( oldCard );
            var currentIndex    = allCards.index( currentCard );
            var isNext          = oldIndex < currentIndex;

            $( '.c-location-map__location' ).removeClass( 'c-location-map__location--active' );
            currentCardAll.addClass( 'c-location-map__location--active' );
            slideCard( oldCard, currentCard, isNext );

            showHiddenCards();

            if ( currentCard ) {
                if ( window.innerWidth < mobileWidth ) {
                    setArrows( currentCard );
                }
            }
        }

        function scrollToCard( id ) {

            scrolling = true;
            const $el = $( '.js-map-location-scroll' );
            const card = $el.find( "[data-id='"+id+"']" );

            $el.animate({
                scrollTop: ( $el.scrollTop() + card.position().top ) - 3
            }, 1000, ()=> {
                setTimeout(() => {
                    card.find("h2").eq(0).trigger("click")
                    scrolling = false
                }, 100);
            });
        }

        function showHiddenCards() {
            $( '.c-location-map__locations--mobile' ).removeClass( 'c-location-map__locations--hidden' );
            $( '.c-location-map__map-controls' ).removeClass( 'c-location-map__map-controls--hidden' );
        }

        function slideCard( oldCard, currentCard, isNext = true ) {
            const windowWidth = $( window ).width();

            if ( windowWidth >= mobileWidth ) {
                $( '.is-next' ).removeClass( 'is-next' );
                $( '.is-prev' ).removeClass( 'is-prev' );
                currentCard.addClass( 'is-active' );
                return false
            }

            if ( isNext ) {
                oldCard.animate( {left: '-100%'} );
                currentCard.css( {left: '100%'} ).animate( {left: '0'} ).addClass( 'is-active' );
                oldCard.removeClass( 'is-active' );
            } else {
                oldCard.animate( {left: '100%'} );
                oldCard.removeClass( 'is-active' );
                currentCard.css( {left: '-100%'} ).animate( {left: '0'} ).addClass( 'is-active' );
            }

            setTimeout(() => {
                $( '.is-next' ).removeClass( 'is-next' );
                $( '.is-prev' ).removeClass( 'is-prev' );
            }, 350);
        }

        function setArrows( currentCard ) {
            var nextArrow = $( '.c-location-map__control--next' );
            var prevArrow = $( '.c-location-map__control--prev' );
            const isPrev = currentCard.prevAll( ':visible' ).length;
            const isNext = currentCard.nextAll( ':visible' ).length;

            if ( !isNext ) {
                nextArrow.prop( 'disabled', true );
            } else {
                nextArrow.prop( 'disabled', false );
            }

            if ( !isPrev ) {
                prevArrow.prop( 'disabled', true );
            } else {
                prevArrow.prop( 'disabled', false );
            }
        }

        /* init */
        createMap($map[0], mapArgs);

        const activeId = $( '#default-active' ).data( 'id' );

        getLocations(url+(activeId || 0), {map, markers});

        $( '.js-map-location-btn' ).each( function() {
            $( this ).click( function() {
                var id = $( this ).data( 'id' );

                map.setZoom(12);

                getLocations(url+id, {map, markers});
            } );
        } );

        $( '.js-map-location-scroll' ).scroll( function() {
            if ( scrolling )
                return false;

            var cutoff = $( this ).offset().top;

            $( '.js-map-location-card' ).each( function() {
                if ( $( this ).offset().top > cutoff ) {
                    const id = $( this ).data( "id" );
                    setSelectedMarker( id, true );
                    return false; // stops the iteration after the first one on screen
                }
            } );
        } );

        $( '.js-toggle-map-view' ).click( function() {
            $( '.c-location-map__map-wrapper' ).toggleClass( 'c-location-map__map-wrapper--opened' );
            $( '.c-location-map__locations' ).toggleClass( 'c-location-map__locations--opened' );
            $( '.c-location-map__map-controls' ).toggleClass( 'c-location-map__map-controls--opened' );

            if ( $( 'body' ).hasClass( 'has-map-view' ) ) {
                $( 'body' ).css( {overflow: ""} ).removeClass( 'has-map-view' );
                $( '#map-section' ).removeClass( 'c-location-map__location-wrapper--show-map' );
            } else {

                // click the first map
                // setSelectedMarker( firstID, true );

                $( 'body' ).css( {overflow: "hidden"} ).addClass( 'has-map-view' );
                $( '#map-section' ).addClass( 'c-location-map__location-wrapper--show-map' );
            }

            if ( $( '.l-headroom' ).hasClass('l-headroom--unpinned') ) {
                $( '.l-headroom' ).removeClass( 'l-headroom--unpinned' );
                $( '.l-headroom' ).addClass( 'l-headroom--pinned' );
            }

            $( this ).toggleClass( 'c-location-map__toggle-map--opened' );
        } );

        $( window ).on( 'resize', function () {
            if (
                $( '.c-location-map__map-wrapper' ).hasClass( 'c-location-map__map-wrapper--opened' ) &&
                $( '.c-location-map__locations' ).hasClass( 'c-location-map__locations--opened' ) &&
                $( '.c-location-map__map-controls' ).hasClass( 'c-location-map__map-controls--opened' )
            )
            {
                $( '.c-location-map__map-wrapper' ).removeClass( 'c-location-map__map-wrapper--opened' );
                $( '.c-location-map__locations' ).removeClass( 'c-location-map__locations--opened' );
                $( '.c-location-map__map-controls' ).removeClass( 'c-location-map__map-controls--opened' );
            }

            if ( $( 'body' ).hasClass( 'has-map-view' ) ) {
                $( 'body' ).css( {overflow: ""} ).removeClass( 'has-map-view' );
                $( '#map-section' ).removeClass( 'c-location-map__location-wrapper--show-map' );
            }
        } );

        $( '.c-location-map__control' ).click( function() {
            var action = $( this ).data( 'do' );
            var oldCard = $( '.c-location-map__location--mobile.is-active' );

            if ( action === 'next-card' ) {
                const currentCard = oldCard.nextAll( 'div:visible' ).first();
                const id = currentCard.data( 'id' );

                if ( id ) {
                    setSelectedMarker( currentCard.data( 'id' ), true );
                }
            } else if ( action === 'prev-card' ) {
                const currentCard  = oldCard.prevAll( 'div:visible' ).first();
                const id = currentCard.data( 'id' );

                if ( id ) {
                    setSelectedMarker( currentCard.data( 'id' ), true );
                }
            } else if ( action === "hide-cards" ) {
                $( '.c-location-map__locations--mobile' ).addClass( 'c-location-map__locations--hidden' );
                $( '.c-location-map__map-controls' ).addClass( 'c-location-map__map-controls--hidden' );
            }
        } );

        $( '.c-card-location__title' ).click( function () {
            var id = $( this ).data( 'location' );

            if( id ) {
                setSelectedMarker( id, true, true );
            }
        } );

        $( '.c-card-location-image-top__icon-svg' ).click( function () {
            var location_id = $( this ).data( 'location' );

            if ( window.innerWidth >= mobileWidth ) {
                $( '.c-location-map__map-toggle-btn' ).trigger( 'click' );

                var toScroll = $(".c-location-map__container").offset().top

                $( 'html, body' ).animate( {
                    scrollTop: (toScroll - 75)
                }, 1000 );

                scrollToCard( location_id );
            } else {
                $( '.c-location-map__toggle-map' ).trigger( 'click' );
            }

            if ( location_id ) {
                setSelectedMarker( location_id, true, true );
            }
        } )

        $(".view-on-map-button").click(function() {

            var location_id = $( this ).data( 'location' );

            if ( window.innerWidth >= mobileWidth ) {
                $( '.c-location-map__map-toggle-btn' ).trigger( 'click' );

                var toScroll = $(".c-location-map__container").offset().top

                $( 'html, body' ).animate( {
                    scrollTop: (toScroll - 75)
                }, 1000 );

                scrollToCard( location_id );
            } else {
                $( '.c-location-map__toggle-map' ).trigger( 'click' );
            }

            if ( location_id ) {
                setSelectedMarker( location_id, true, true );
            }
        })

        $(".collapsable_trigger").click(function(){
            $(".collapsable").toggleClass("open")
            $(this).find(".c-location-map__svg-shape-wrapper").toggleClass("rotate")
        })
    });
}
