  <template>
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.25 5.54502C0.25 2.64442 2.81145 0.25 6 0.25C9.18783 0.25 11.75 2.64376 11.75 5.56872C11.75 5.9673 11.703 6.34314 11.609 6.71915L6.00021 19.3822L0.39106 6.69553C0.296989 6.31949 0.25 5.94363 0.25 5.54502ZM3.38333 5.45498C3.38333 6.81978 4.57317 7.89218 6 7.89218C7.42426 7.89218 8.61667 6.83873 8.61667 5.47156C8.61667 4.10676 7.42684 3.03436 6 3.03436C4.57574 3.03436 3.38333 4.08781 3.38333 5.45498Z" fill="#004FCB" stroke="#0A1352" stroke-width="0.5"/>
    </svg>
  </template>
  
  <script>
  export default {
    data: function () {
      return {};
    },
    props: {
      fill: {
        type: String,
        default: '#1F1F15',
      },
    },
  };
  </script>
  