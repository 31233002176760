var selector           = '.js-nav-toggler';
var selector2          = '.js-nav-toggler-label';
var openedClass        = 'c-slide-out__nav-toggler--opened';
var nav                = '.c-nav'
var navClass           = 'c-nav--opened'
var parent             = '.c-slide-out__column'
var icon               = '.c-slide-out__svg-shape-wrapper'
var iconOpenedClass    = 'c-slide-out__svg-shape-wrapper--opened'

export function navToggler() {
    $(selector).click(function(e) {
        e.preventDefault();
        $(this).closest(parent).siblings().find(selector).removeClass(openedClass);
        $(this).toggleClass(openedClass);
        $(this).find(icon).toggleClass(iconOpenedClass);
        $(this).closest(parent).find(nav).slideToggle().toggleClass(navClass);
        $(this).closest(parent).siblings().find(nav).slideUp().removeClass(navClass);
    });

    $(selector2).click(function(e) {
        // e.preventDefault();
        // $(this).siblings().find(selector).removeClass(openedClass);
        // $(this).siblings().find(selector).toggleClass(openedClass);
        // $(this).closest(parent).find(nav).slideToggle().toggleClass(navClass);
        // $(this).closest(parent).siblings().find(nav).slideUp().removeClass(navClass);
        // FIX THIS
    });
}

console.log("slide out basic")

document.querySelectorAll('.menu-toggle').forEach(function (node) {
    node.addEventListener('click', function (e) {
      let elm = e.target;
      if (!elm.classList.contains('menu-toggle')) {
        elm = elm.closest('.menu-toggle');
      }

      elm.querySelector('svg').classList.toggle('open');

      var parentofSelected = elm.parentNode; // gives the parent DIV

      var children = parentofSelected.childNodes;
      for (var i = 0; i < children.length; i++) {
        if (typeof children[i].classList !== 'undefined') {
          if (children[i].classList.contains('menu-items')) {
            children[i].classList.toggle('open');
            break;
          }
        }
      }

      // TODO: if something is open, disable scroll
    });
  });