function logo(elm) {
    // var ppp          = -0.2;
    // var hhh          = -5;
    // var wid          = 6.165;
    // var myAarray     = new Array;
    // var globCount    = 0.1;
    // // var xx           = 0;
    // function wave() {
    //     var currentColor = $(elm).css("color")
    //     var counter = 0;
    //     var ce = 0;
    //     var jj = 0;
    //     var increase = Math.PI / 90 + wid;
    //     // var example = elm
    //     elm.width = 290;
    //     elm.height = 32;
    //     var withi = window.innerWidth / 5
    //     for (let i = 0; i <= withi; i++) {
    //         let x = i;
    //         let y = Math.sin(counter + globCount);
    //         console.log()
    //         counter += increase;
    //         var pp = 1 + y * hhh;
    //         if (myAarray[i] == undefined || myAarray[i] == '') {
    //             myAarray[i] = elm.getContext('2d');
    //         }
    //
    //
    //         myAarray[i].beginPath();
    //         myAarray[i].lineWidth = "5";
    //         myAarray[i].strokeStyle = currentColor; // Green path
    //         myAarray[i].moveTo(0 + jj, 0);
    //         myAarray[i].lineTo(jj, pp + 22);
    //         myAarray[i].stroke(); // Draw it
    //         jj += 4.5;
    //     }
    //
    //     globCount += 0.1 + ppp;
    // }
    //
    // setInterval(()=> {
    //     wave()
    // }, 50);
}
export default logo